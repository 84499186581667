const LegalRepresentativeTypeEnum = {
    LEGAL_REPRESENTATIVE: "LEGAL_REPRESENTATIVE",
    REFUGEE_CLAIMANT: "REFUGEE_CLAIMANT",
    ORGANIZATION: "ORGANIZATION",
    FOSTER_FAMILY: "FOSTER_FAMILY",
    DROP_IN_CENTER: "DROP_IN_CENTER",
};
const LegalRepresentativeCivilityEnum = {
    MONSIEUR: "MONSIEUR",
    MADAME: "MADAME"
};

const LegalRepresentativeCivilityConfig = {
    "MONSIEUR": {label: "MONSIEUR"},
    "MADAME": {label: "MADAME"},
};
const LRCivilityOptions = Object.values(LegalRepresentativeCivilityEnum).map((civility, idx) => ({
    id: civility,
    name: LegalRepresentativeCivilityConfig[civility]?.label,
}));

export {
    LegalRepresentativeTypeEnum,
    LegalRepresentativeCivilityEnum,
    LegalRepresentativeCivilityConfig,
    LRCivilityOptions
};