import React, {useMemo} from "react";
import ListSubscribers from "@/components/subscriber-list/ListSubscribers";
import SubscriberService from "@/service/SubscriberService";
import {Box, Grid, Link as MuiLink} from "@mui/material";
import HeaderMenu from "@/components/common/ui/HeaderMenu";
import InfoBox from "@/components/common/ui/InfoBox";
import Routes from "@/Routes";
import {Link} from "react-router-dom";
import {useLoader} from "@/components/common/ui/LoaderContext";
import {RegistrationStatusEnum} from "frontend.common/src/SubscriptionConstants";
import {useLegalRepresentative} from "@/components/common/LegalRepresentativeContext";
import useStateOpenClose from "@/hooks/useStateOpenClose";
import Page from "@/components/common/ui/Page";
import PaymentService from "@/service/PaymentService";
import {BillStatusEnum, PaymentAttempsStatusEnum, PaymentTypeEnum} from "frontend.common/src/PaymentConstants";
import ButtonLink from "@/components/common/ui/ButtonLink";
import useMemoAsync from "@/hooks/useMemoAsync";
import {useSnackbar} from "notistack";
import HelperPopin from "@/components/common/helper-popin/HelperPopin";
import {useSettingsContext} from "@/components/common/settings/SettingsContext";
import {useDisplay} from "@/components/common/ui/DisplayContext";
import LinkDeclareSubscribers from "@/components/subscriber-list/LinkDeclareSubscribers";
import {LegalRepresentativeTypeEnum} from "frontend.common/src/LegalRepresentativeConstants";


const paymentStatusesForAlert = [
    PaymentAttempsStatusEnum.ONLINE_PROCEDURE_SENT,
    PaymentAttempsStatusEnum.ECHEC,
    PaymentAttempsStatusEnum.REJECTED,
    PaymentAttempsStatusEnum.TIMED_OUT,
];

function ManageSubscribers() {

    const loader = useLoader();
    const {enqueueSnackbar} = useSnackbar();
    const legalRepresentative = useLegalRepresentative();

    const [isHelperOpened, openHelper, closeHelper] = useStateOpenClose(false);
    const [emailValidated, setEmailValidated] = React.useState(legalRepresentative.emailValidated);

    const subscriberRequests = useMemoAsync(async () => {
        loader.showLoader();
        try {
            const {data: subscriberRequests} = await SubscriberService.getAll();
            return subscriberRequests;
        } catch (e) {
            enqueueSnackbar("Une erreur est survenue lors de la récupération de vos enfants", {variant: "error"});
            return [];
        } finally {
            loader.hideLoader();
        }
    }, [], []);

    const subscriptionsToPay = useMemo(() => {
        return subscriberRequests.filter(sub => {
            return sub.status === RegistrationStatusEnum.WAITING_FOR_PAYMENT;
        });
    }, [subscriberRequests]);

    const hasSubscriptionsToPay = useMemo(() => {
        return subscriptionsToPay.some(sub => {
            return !sub.paymentStarted;
        });
    }, [subscriptionsToPay]);

    const hasSubscriptionPaymentStarted = useMemoAsync(async () => {
        if (!subscriptionsToPay.length) {
            return false;
        }

        const {data: bills} = await PaymentService.getBills();

        return bills.some(bill => {
            let isCbWebToCash = bill.type === PaymentTypeEnum.CB_WEB && bill.status === BillStatusEnum.TO_CASH;
            let hasLastPaymentAttempt = bill.lastPaymentAttempt != null;
            if (isCbWebToCash && hasLastPaymentAttempt) {
                if (paymentStatusesForAlert.indexOf(bill.lastPaymentAttempt.status) > -1) {
                    return true;
                }
            }

            return false;
        });
    }, [subscriptionsToPay]);

    React.useEffect(() => {
        setEmailValidated(legalRepresentative.emailValidated);
    }, [legalRepresentative.emailValidated]);



    const {
        TOOLTIP_IMAGE_URL: tooltipIcon,
        TRANSPORT_RULES_LINK: transportRulesLink,
        HAS_TREASURY_ORMC,
        WKF_IEL__SUBSCRIPTION_DURING_SCHOOL_YEAR_ENABLED: isSubscriptionOpenDuringSchoolYear,
    } = useSettingsContext();


    const background = window._env_.REACT_APP_PROJECT === "RNA" ? "main-background" : "default-background";

    const {isDesktop} = useDisplay();

    const linkTransportRules = <MuiLink href={transportRulesLink} target="_blank">Consulter le règlement des
        transports</MuiLink>;

    const hasSubscriptions = subscriberRequests.length > 0;

    const hasOnlyLr2Subscriptions = subscriberRequests.every(subscriberRequest => subscriberRequest?.content?.subscriber?.lr2?.id === legalRepresentative.id);

    const isFosterFamilyOrDropInCenter = legalRepresentative.legalRepresentativeType === LegalRepresentativeTypeEnum.FOSTER_FAMILY
        || legalRepresentative.legalRepresentativeType === LegalRepresentativeTypeEnum.DROP_IN_CENTER;

    const canAddNewSubscription = !hasSubscriptions || isSubscriptionOpenDuringSchoolYear || hasOnlyLr2Subscriptions || isFosterFamilyOrDropInCenter;

    return (
        <main className={background}>
            <Page>
                <Page.Header>
                    <HeaderMenu noBurger={!emailValidated}/>
                </Page.Header>
                <Page.Body>
                    <Grid container spacing={2} sx={{p: 2}}>
                        <Grid item xs={12}>
                            <h1>Bonjour {legalRepresentative.firstName},</h1>
                        </Grid>

                        {emailValidated &&
                            <>
                                <Grid item xs={12}>
                                    <Box p={2} pl={0} className="subtitle-home">
                                        Gérez {!HAS_TREASURY_ORMC && "et payez"} l'inscription de vos enfants au
                                        transport scolaire.
                                    </Box>
                                    <InfoBox logo={tooltipIcon}>
                                        Pour obtenir de l’aide sur comment inscrire vos
                                        enfants, <span className={"link"} onClick={openHelper}>cliquez ici</span>.
                                    </InfoBox>
                                    <HelperPopin isOpen={isHelperOpened} onClose={closeHelper}/>
                                </Grid>

                                {hasSubscriptionPaymentStarted &&
                                    <Grid item xs={12}>
                                        <InfoBox logo={tooltipIcon}>
                                            Certains dossiers en attente de paiement n'ont pas été réglés. Pour
                                            reprendre le paiement, <Link className={"link"} to={Routes.BILLING_HISTORY}>cliquez
                                            ici</Link>.
                                        </InfoBox>
                                    </Grid>
                                }

                                {isDesktop && <Grid item xs={12}>{linkTransportRules}</Grid>}

                                {hasSubscriptions &&
                                    <Grid item xs={12}>
                                        <ListSubscribers subscriberRequests={subscriberRequests} legalRepresentative={legalRepresentative}/>
                                    </Grid>
                                }

                                {canAddNewSubscription &&
                                    <Grid item xs={12}>
                                        <LinkDeclareSubscribers/>
                                    </Grid>
                                }

                                {!isDesktop &&
                                    <Grid item xs={12} style={{textAlign: "center"}}>{linkTransportRules}</Grid>
                                }
                            </>
                        }
                        {!emailValidated &&
                            <InfoBox style={{
                                marginTop: "25px",
                                paddingTop: "25px",
                                paddingBottom: "25px",
                            }} center={true}>
                                <p className="subtitle-home">
                                    Votre adresse électronique n'a pas été validée, merci de cliquer sur le lien
                                    contenu
                                    dans le courriel
                                    que vous avez dû recevoir à l'adresse
                                    suivante: <br/><strong>{legalRepresentative?.email}</strong>
                                </p>
                            </InfoBox>
                        }
                    </Grid>
                </Page.Body>

                {hasSubscriptionsToPay &&
                    <Page.Footer>
                        <ButtonLink to={Routes.PAYMENT}>Payer les inscriptions validées</ButtonLink>
                    </Page.Footer>
                }
            </Page>
        </main>
    );
}

export default ManageSubscribers;